<template>
  <div class="epicentral">
    <div class="content">
      <div class="epicentral-banner">
        <picture>
          <source
            :srcset="require('@/assets/img/epicentral/banner_mob.jpg')"
            media="(max-width: 767px)"
          />
          <source
            :srcset="require('@/assets/img/epicentral/banner_tab.jpg')"
            media="(max-width: 1220px)"
          />
          <source
            :srcset="require('@/assets/img/epicentral/banner.jpg')"
            media="(min-width: 1221px)"
          />
          <img
            class="epicentral-banner__img"
            :src="require('@/assets/img/epicentral/banner.jpg')"
          />
        </picture>
      </div>
    </div>
    <Breadcrumbs :pages="[{ name: 'Epicentral' }]" />
    <div class="content">
      <div class="epicentral__blocks">
        <div class="epicentral__block">
          <div class="epicentral__block-head">
            <div class="epicentral__block-head-icon">
              <svg
                width="68"
                height="51"
                viewBox="0 0 68 51"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24.6793 39.0707C29.4727 39.0707 33.3586 35.1847 33.3586 30.3911C33.3586 25.5974 29.4727 21.7114 24.6793 21.7114C19.8858 21.7114 16 25.5974 16 30.3911C16 35.1847 19.8858 39.0707 24.6793 39.0707Z"
                  fill="#4FBFEE"
                />
                <path
                  d="M40.809 17.1589C41.4263 16.6301 42.1675 16.2661 42.9634 16.1007C43.7593 15.9354 44.5841 15.9741 45.361 16.2133C46.2801 16.5445 47.12 17.0639 47.8268 17.7384C48.5336 18.4128 49.0918 19.2275 49.4657 20.1301C49.8395 21.0327 50.0208 22.0035 49.998 22.9802C49.9751 23.9569 49.7485 24.9181 49.3328 25.8022C48.9525 26.521 48.3964 27.1317 47.7164 27.5775C47.0363 28.0232 46.2545 28.2895 45.4437 28.3516C43.9111 28.4547 42.3822 28.1021 41.0495 27.3381C39.8751 26.8248 38.9451 25.8768 38.4542 24.6928C37.9634 23.5088 37.95 22.1808 38.4168 20.9871C38.8183 19.5026 39.6507 18.1705 40.809 17.1589Z"
                  fill="#590A95"
                />
              </svg>
            </div>
            <div class="epicentral__block-head-title">
              Субоптимальный контроль&nbsp;заболевания 
            </div>
          </div>
          <div class="epicentral__block-body">
            Несмотря на успехи в диагностике и терапии заболеваний дыхательных
            путей у части пациентов все же не удается добиться полного контроля
            заболевания<sup>1,2</sup>.  Для повышения эффективности терапии
            решающее значение имеет понимание центральной роли эпителия как
            органа, отвечающего за функционирование иммунной системы, при
            заболеваниях как верхних, так и нижних дыхательных
            путей<sup>3-5</sup>.
          </div>
        </div>
        <div class="epicentral__block">
          <div class="epicentral__block-head">
            <div class="epicentral__block-head-icon">
              <svg
                width="68"
                height="51"
                viewBox="0 0 68 51"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24.6793 39.0707C29.4727 39.0707 33.3586 35.1847 33.3586 30.3911C33.3586 25.5974 29.4727 21.7114 24.6793 21.7114C19.8858 21.7114 16 25.5974 16 30.3911C16 35.1847 19.8858 39.0707 24.6793 39.0707Z"
                  fill="#4FBFEE"
                />
                <path
                  d="M40.809 17.1589C41.4263 16.6301 42.1675 16.2661 42.9634 16.1007C43.7593 15.9354 44.5841 15.9741 45.361 16.2133C46.2801 16.5445 47.12 17.0639 47.8268 17.7384C48.5336 18.4128 49.0918 19.2275 49.4657 20.1301C49.8395 21.0327 50.0208 22.0035 49.998 22.9802C49.9751 23.9569 49.7485 24.9181 49.3328 25.8022C48.9525 26.521 48.3964 27.1317 47.7164 27.5775C47.0363 28.0232 46.2545 28.2895 45.4437 28.3516C43.9111 28.4547 42.3822 28.1021 41.0495 27.3381C39.8751 26.8248 38.9451 25.8768 38.4542 24.6928C37.9634 23.5088 37.95 22.1808 38.4168 20.9871C38.8183 19.5026 39.6507 18.1705 40.809 17.1589Z"
                  fill="#590A95"
                />
              </svg>
            </div>
            <div class="epicentral__block-head-title">
              Понимание патогенеза  
            </div>
          </div>
          <div class="epicentral__block-body">
            Для понимания патогенеза респираторных заболеваний важно изучить
            воспалительные процессы 2-го типа и не только, которые начинаются с
            эпителия<sup>3-7</sup>. Таким образом, более глубокое понимание
            влияния эпителия при заболеваниях дыхательных путей должно
            способствовать поиску оптимальных решений для достижения контроля и
            ремиссии заболевания<sup>8-10</sup>.
          </div>
        </div>
        <div class="epicentral__block">
          <div class="epicentral__block-head">
            <div class="epicentral__block-head-icon">
              <svg
                width="68"
                height="51"
                viewBox="0 0 68 51"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24.6793 39.0707C29.4727 39.0707 33.3586 35.1847 33.3586 30.3911C33.3586 25.5974 29.4727 21.7114 24.6793 21.7114C19.8858 21.7114 16 25.5974 16 30.3911C16 35.1847 19.8858 39.0707 24.6793 39.0707Z"
                  fill="#4FBFEE"
                />
                <path
                  d="M40.809 17.1589C41.4263 16.6301 42.1675 16.2661 42.9634 16.1007C43.7593 15.9354 44.5841 15.9741 45.361 16.2133C46.2801 16.5445 47.12 17.0639 47.8268 17.7384C48.5336 18.4128 49.0918 19.2275 49.4657 20.1301C49.8395 21.0327 50.0208 22.0035 49.998 22.9802C49.9751 23.9569 49.7485 24.9181 49.3328 25.8022C48.9525 26.521 48.3964 27.1317 47.7164 27.5775C47.0363 28.0232 46.2545 28.2895 45.4437 28.3516C43.9111 28.4547 42.3822 28.1021 41.0495 27.3381C39.8751 26.8248 38.9451 25.8768 38.4542 24.6928C37.9634 23.5088 37.95 22.1808 38.4168 20.9871C38.8183 19.5026 39.6507 18.1705 40.809 17.1589Z"
                  fill="#590A95"
                />
              </svg>
            </div>
            <div class="epicentral__block-head-title">
              Регулярное наблюдение 
            </div>
          </div>
          <div class="epicentral__block-body">
            Более раннее и регулярное наблюдение имеет решающее значение для
            лечения динамичных и сложных заболеваний, связанных с вовлечением
            эпителия, для улучшения результатов терапии<sup>7,11,12</sup>.
          </div>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="epicentral__theme">
        <div class="epicentral__theme-img">
          <picture>
            <source
              :srcset="require('@/assets/img/epicentral/theme1_mob.jpg')"
              media="(max-width: 767px)"
            />
            <source
              :srcset="require('@/assets/img/epicentral/theme1_tab.jpg')"
              media="(max-width: 1220px)"
            />
            <source
              :srcset="require('@/assets/img/epicentral/theme1.png')"
              media="(min-width: 1221px)"
            />
            <img :src="require('@/assets/img/epicentral/theme1.png')" />
          </picture>
        </div>
        <div class="epicentral__theme-text">
          <div class="epicentral__theme-title">Незакрытые потребности</div>
          <div class="epicentral__theme-description">
            Узнайте больше о сложной природе заболеваний дыхательных путей.
          </div>
        </div>
      </div>
      <div
        class="epicentral__materials"
        v-if="EpicentralList && EpicentralList.length"
      >
        <swiper
          class="epicentral__materials-slider"
          v-if="filteredMaterials && filteredMaterials.length"
          v-bind="swiperSettings"
          :navigation="firstSliderNav"
        >
          <swiper-slide v-for="i in filterByGroup('group1').length" :key="i">
            <EpicentralMaterial
              @toMaterial="toMaterial"
              :item="filterByGroup('group1')[i - 1]"
            />
          </swiper-slide>
        </swiper>
        <div class="epicentral__materials-arrows">
          <div
            class="epicentral__materials-arrow epicentral__materials-arrow_left"
            id="left1"
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 6L5 16L15 26"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="square"
              />
              <path
                d="M7 16L27 16"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="square"
              />
            </svg>
          </div>
          <div
            class="
              epicentral__materials-arrow epicentral__materials-arrow_right
            "
            id="right1"
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17 6L27 16L17 26"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="square"
              />
              <path
                d="M25 16L5 16"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="square"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="epicentral__theme">
        <div class="epicentral__theme-img">
          <picture>
            <source
              :srcset="require('@/assets/img/epicentral/theme2_mob.jpg')"
              media="(max-width: 767px)"
            />
            <source
              :srcset="require('@/assets/img/epicentral/theme2_tab.jpg')"
              media="(max-width: 1220px)"
            />
            <source
              :srcset="require('@/assets/img/epicentral/theme2.png')"
              media="(min-width: 1221px)"
            />
            <img :src="require('@/assets/img/epicentral/theme2.png')" />
          </picture>
        </div>
        <div class="epicentral__theme-text">
          <div class="epicentral__theme-title">Нижние дыхательные пути</div>
          <div class="epicentral__theme-description">
            Изучите роль эпителия в развитии заболеваний нижних дыхательных
            путей
          </div>
        </div>
      </div>
      <div
        class="epicentral__materials"
        v-if="EpicentralList && EpicentralList.length"
      >
        <swiper
          class="epicentral__materials-slider"
          v-if="filteredMaterials && filteredMaterials.length"
          v-bind="swiperSettings"
          :navigation="secondSliderNav"
        >
          <swiper-slide v-for="i in filterByGroup('group2').length" :key="i">
            <EpicentralMaterial
              @toMaterial="toMaterial"
              :item="filterByGroup('group2')[i - 1]"
            />
          </swiper-slide>
        </swiper>
        <div class="epicentral__materials-arrows">
          <div
            class="epicentral__materials-arrow epicentral__materials-arrow_left"
            id="left2"
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 6L5 16L15 26"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="square"
              />
              <path
                d="M7 16L27 16"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="square"
              />
            </svg>
          </div>
          <div
            class="
              epicentral__materials-arrow epicentral__materials-arrow_right
            "
            id="right2"
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17 6L27 16L17 26"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="square"
              />
              <path
                d="M25 16L5 16"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="square"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="materials-page__text">
        ПГКС - пероральные глюкокортикостероиды
      </div>
      <div class="materials-page__text">
        1. Global Strategy for Asthma Management and Prevention, Global
        Initiative for Asthma (GINA) 2024. [Электронный ресурс]. 17.07.2024 URL:
        http://www.ginasthma.org
        <br />2. Wang E, et al. Chest. 2020;157:790–804 <br />3. Bartemes KR, et
        al. Clin Immunol. 2012;143:222–235 <br />4. Gauvreau GM, et al. Expert
        Opin Ther Targets. 2020;24:777–792 <br />5. Fokkens W, et al. Clin N Am.
        2023;56:1–10 <br />6. Cohen L, et al. Am J Respir Crit Care Med.
        2007;176:138–145 <br />7. Chapman DG, et al. Clin Exp Allergy.
        2015;45:706–719 <br />8. Menzies-Gow A, et al. J Allergy Clin Immunol
        2020;145:757–65 <br />9. Thomas D, et al. Eur Respir J. 2022;60:2102583
        <br />10. Lommatzsch M, et al. Lancet. 2022;399(10335):1664–1668
        <br />11. Busse WW. Allergol Int. 2019;68:158–166 <br />12. Tran TN, et
        al. Ann Allergy Asthma Immunol. 2016;116:37–42.
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/pageComponents/Breadcrumbs.vue";
import EpicentralMaterial from "../../components/pageComponents/EpicentralMaterialNew.vue";
import { mapActions, mapGetters } from "vuex";
import { bus } from "@/main";
import "swiper/swiper-bundle.css";
import { Navigation, Pagination } from "swiper";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
SwiperCore.use([Navigation, Pagination]);
export default {
  name: "Epicentral",
  components: {
    Breadcrumbs,
    EpicentralMaterial,
    Swiper,
    SwiperSlide,
  },
  data: () => ({
    swiperSettings: {
      slidesPerView: 3,
      spaceBetween: 30,

      breakpoints: {
        320: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 2,
        },
        1280: {
          slidesPerView: 3,
        },
      },
    },
  }),
  computed: {
    ...mapGetters(["user", "EpicentralList"]),
    firstSliderNav() {
      return {
        nextEl: "#right1",
        prevEl: "#left1",
      };
    },
    secondSliderNav() {
      return {
        nextEl: "#right2",
        prevEl: "#left2",
      };
    },
    filteredMaterials() {
      return this.EpicentralList
        ? this.EpicentralList.filter((el) => el.active)
        : [];
    },
    firstGroup() {
      return this.filteredMaterials.filter(
        (el) => el.groups.findIndex((g) => g.slug == "group1") > -1
      );
    },
  },
  methods: {
    ...mapActions(["fetchEpicentralMaterials"]),
    toTop() {
      document.body.scrollIntoView({ behavior: "smooth" });
    },
    filterByGroup(group) {
      return this.filteredMaterials.filter(
        (el) => el.groups.findIndex((g) => g.slug == group) > -1
      );
    },
    toMaterial(slug) {
      this.$router
        .push({ name: "EpicentralDetail", params: { slug: slug } })
        .catch(() => {});
    },
  },
  async mounted() {
    await this.fetchEpicentralMaterials();
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.epicentral {
  &-banner {
    width: 100%;

    &__img {
      width: 100%;
      height: auto;
      object-fit: cover;
      object-position: center;
    }
  }

  &__title {
    margin-top: 40px;
    color: #55a9dd;
    font-family: "Roboto Slab", sans-serif;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
  }

  &__blocks {
    margin: 40px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 32px;

    @media screen and (max-width: 1220px) {
      grid-template-columns: repeat(1, 1fr);
      row-gap: 24px;
    }

    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
      row-gap: 24px;
    }
  }

  &__block {
    &-head {
      margin-bottom: 16px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &-icon {
        width: 68px;
        flex-shrink: 0;
        margin-right: 16px;
      }

      &-title {
        font-family: "Roboto Slab", sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
        color: #55a9dd;

        @media screen and (max-width: 767px) {
          font-size: 18px;
          line-height: 24px;
        }
      }
    }

    &-body {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: #1f1f1f;
    }
  }

  &__theme {
    padding: 24px;
    border: 1px solid #d2d2d2;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
      padding: 0;
    }

    &-img {
      margin-right: 32px;
      width: 560px;
      box-shadow: 0px 4px 4px 0px #00000040;
      flex-shrink: 0;
      border-radius: 16px;

      @media screen and (max-width: 1220px) {
        width: 100%;
        margin-right: 0;
      }

      & img {
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &-text {
      @media screen and (max-width: 1220px) {
        padding: 32px 16px;
      }
    }

    &-title {
      margin-bottom: 24px;
      font-family: "Roboto Slab", sans-serif;
      font-size: 40px;
      font-weight: 400;
      line-height: 48px;
      color: #590a95;

      @media screen and (max-width: 1220px) {
        font-size: 24px;
        line-height: 22px;
      }
    }

    &-description {
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      color: #1f1f1f;

      @media screen and (max-width: 1220px) {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }

  &__materials {
    padding: 0 8px;
    position: relative;
    &-slider {
      margin: 32px 0 40px;
    }
    .swiper-slide {
      height: auto;
    }

    &-arrows {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 5;
      width: 100%;
      left: 0;

      @media screen and (max-width: 767px) {
        position: static;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 32px;
      }
    }

    &-arrow {
      position: absolute;
      top: 0;
      width: 46px;
      height: 46px;
      border-radius: 50%;
      border: 1px solid #830051;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: 0.3s;
      color: #830051;
      background-color: #fff;
      z-index: 5;
      user-select: none;

      @media screen and (max-width: 767px) {
        position: static;
        transform: none !important;
      }

      &:hover {
        background-color: #830051;
        color: #fff;
      }

      &_left {
        left: 0;
        transform: translateX(-50%);
      }

      &_right {
        right: 0;
        transform: translateX(50%);
      }
    }
  }
}

.materials-page {
  &__list {
    margin-top: 32px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 32px;
    row-gap: 32px;

    @media screen and (max-width: 1220px) {
      grid-template-columns: repeat(2, 1fr);
      row-gap: 24px;
    }

    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
      row-gap: 24px;
    }
  }

  &__text {
    margin-top: 32px;
    color: #1f1f1f;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  &__load {
    margin: 32px auto 0;
    width: fit-content;
  }
}
</style>

<style lang="scss">
sup,
sub {
  vertical-align: super;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: currentColor;
  white-space: nowrap;
}
</style>